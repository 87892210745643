
/******************************************************************************************************
 ******************************************************************************************************
Global Scrollbars
 ******************************************************************************************************
 ******************************************************************************************************/


typeahead-container {
  z-index: 1001 !important;
}

.spinner-grow{
  border-radius: 0 !important;
  height: 0.6rem !important;
}

.modal-square .modal-content {
  max-width: 550px!important;
  padding-bottom: 50px!important;
}

@media screen and (max-width: 768px){
  .modal-square .modal-dialog{
    width: 100%!important;
    margin: 0!important;
  }
  .modal-square .modal-content{
      width: 100%!important;
      max-width: 100%;
      padding-bottom: 0;
  }
}